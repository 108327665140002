import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData } from '@/mock/SiteData';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { TitleCopy } from '@/components/shared/title-copy';
import { ImageGallery } from '@/components/shared/image-gallery';
import { cn } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import VueLogo from '@/assets/images/technologies/vue.webp';
import LaravelLogo from '@/assets/images/technologies/laravel.webp';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy = CaseStudyData.find((m) => m.id === 'ahora-vuelvo-mama');

const AhoraVuelvoMama = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook
  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const titleCopyThird = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const imageGallerySecond = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const lightSeparatorRef = useScrollAnimation();

  const darkSeparatorRef = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div ref={projectWhiteSectionRef} className="project-white-section">
          <div className="flex flex-col gap-20 bg-white py-20 lg:gap-36 lg:py-36">
            <TitleCopy
              ref={titleCopyFirst}
              title="The Client"
              description="Ahora Vuelvo Mama is a Spanish company whose main objective is to revolutionize the concept of one-day travels. During 2019 they reached more than 8000 passengers."
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGalleryFirst}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[
                { src: '/images/case-studies/ahoravuelvomama/mock-2.webp', alt: 'image2' },
                { src: '/images/case-studies/ahoravuelvomama/mock-3.webp', alt: 'image3' },
              ]}
            />
            <TitleCopy
              ref={titleCopySecond}
              title="The Need"
              description={`The idea was born from one of its founders after his girlfriend gave him a round trip to Paris in the same day. In the Spanish market there was no player that offered integral solutions for this kind of one-day trips.\n\nThe business model was then worked on and refined as a final degree project until they needed to make it a reality. It was at that moment when they came to us with the need to think, plan, design and develop their custom web platform allowing users to book same-day round-trip flights, ending the limitations of their city.`}
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGallerySecond}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[{ src: '/images/case-studies/ahoravuelvomama/mock-4.webp', alt: 'image4' }]}
            />
            <TitleCopy
              ref={titleCopyThird}
              title="The Solution"
              description={`We worked alongside Rica Design during the whole design and development stages with the goal of creating a straightforward, simple, yet entertaining tool that reflects the passion of sharing.\n\nAs for the Frontend, we decided to create a PWA with Vue.js obtaining as a result an extremely lightweight and flexible application. On the backend side, we developed an MVC application usign Laravel.`}
              className={ANIMATED_CLASSNAME}
            />
            <Separator
              ref={lightSeparatorRef}
              className={cn('mx-auto max-w-7xl w-[95%] lg:-mb-36', ANIMATED_CLASSNAME)}
            />
          </div>
        </div>
        <div className="animate-transition flex flex-col gap-36 bg-loop-black lg:py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              { icon: <img className="w-40" src={VueLogo} key="vue" alt="vue" />, id: 'vue' },
              {
                icon: <img className="w-40" src={LaravelLogo} key="laravel" alt="laravel" />,
                id: 'laravel',
              },
            ]}
            description=""
            className={{
              section: cn(ANIMATED_CLASSNAME, 'lg:items-center'),
              text: 'animate-transition-text text-white',
            }}
          />
          <div className="block">
            <AnimatedTagCopy
              tag="the result"
              className={{
                section: cn('mx-auto px-4 md:px-8 xl:px-0'),
                tag: 'animate-transition-text',
              }}
              animatedCopy={{
                id: 'animated-copy-1',
                text: 'We delivered a website that, in addition to its <w>refinement<w> and <w>simplicity<w>, reinforces the values and concepts of the brand.',
                animationStart: 'top 40%',
                className: 'mx-auto',
              }}
            />
            <AnimatedTagCopy
              className={{ section: 'mx-auto mt-12 px-4 md:px-8 xl:px-0' }}
              animatedCopy={{
                id: 'animated-copy-2',
                text: 'Easy to access, <w>dynamic<w> and <w>agile<w>, a website that met the needs of the client and that its users will enjoy browsing. Go ahead! We invite you to discover a site with a <w>narrative<w> like no other.',
                revealDelay: 0.75,
                animationStart: 'top 40%',
                className: 'mx-auto',
              }}
            />
          </div>
          <Separator
            ref={darkSeparatorRef}
            className={cn('mx-auto max-w-[1064px] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default AhoraVuelvoMama;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO
        title="Loop3 Studio - Ahora Vuelvo Mama"
        description={`${caseStudy.hero.heroDescriptionText}`}
      />
    );
  }
};
